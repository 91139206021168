@import url(https://fonts.googleapis.com/css?family=Roboto:300);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css);
* {
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: Montserrat;
  margin: 0;
  padding: 0;
  background-image: linear-gradient(
    to bottom,
    #000000,
    #0b0b0b,
    #131314,
    #191a1a,
    #1f2020,
    #1f2020,
    #1f2020,
    #1f2020,
    #191a1a,
    #131314,
    #0b0b0b,
    #000000
  );
  z-index: -1;
}

a {
  color: #f9f9f9;
  text-decoration: none;
}

::selection {
  color: #000;
  background: rgb(196, 216, 1);
  text-shadow: none;
}


body {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
}

#about {
  margin-bottom: 25vh;
}

.title {
  font-size: 0em;
  text-align: center;
  font-family: "Bebas Neue", sans-serif;
}

.workButton {
  background-color: rgb(44, 44, 44) !important;
  border: 2px solid rgb(196, 216, 1) !important;
}

.schoolButton {
  background-color: rgb(44, 44, 44) !important;
  border: 2px solid rgb(196, 216, 1) !important;
}

.vertical-timeline {
  margin-top: 150px !important;
  margin-bottom: 150px !important;
}

h3 {
  padding-top: 0.25em;
}

.vertical-timeline-element-date {
  color: white !important;
  font-family: "Poppins", Helvetica, sans-sarif !important;
  font-size: 16px;
  letter-spacing: 1.08px;
}

.vertical-timeline-element div {
  box-shadow: rgb(0 0 0 / 80%) 0px 40px 58px -16px,
    rgb(0 0 0 / 72%) 0px 30px 22px -10px !important;
}

.vertical-timeline-element-content {
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25),
    0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15) !important;
  padding: 2em 3em !important;
  background: rgb(26, 25, 25) !important;
  color: white !important;
}

.vertical-timeline::before {
  background: rgb(196, 216, 1) !important;
}

.date {
  color: rgb(201, 251, 255);
}

.vertical-timeline-element-icon {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  border: 2px solid black !important;
}

#description {
  margin: 1.5em 0 2em 0;
}

.button {
  text-decoration: none;
  padding: 0.5em 1em;
  border-radius: 5px;
  color: white;
}

.workButton {
  background-color: #06d6a0;
}

.workButton:hover {
  background-color: #0ac593;
}

.schoolButton {
  background-color: #f9c74f;
}

.schoolButton:hover {
  background-color: #f3bc3c;
}

@media only screen and (max-width: 1700px) {
  .vertical-timeline-element-date {
    display: block !important;
    float: none !important;
    color: rgb(44, 44, 44);
    margin-top: 1.5em;
  }
}

.skillsheader {
  margin-bottom: 52px;
}

.skillsheader {
  font-size: 2.1rem;
  font-weight: 700;
}

.progress-line__outer {
  display: flex;
  margin: 15px auto;
  border-radius: 12px;
}

.progress-line__inner {
  border-radius: 12px;
}

.progress-line:hover {
  transform: scale(1.05);
  transition: all 0.3s ease-in-out;
  border-top-right-radius: 24px;
  box-shadow: -8px -8px 16px #1f2020, 13px 13px 26px #000;
}

.container {
  justify-content: center;
  margin-left: calc(100% - 80vw);
  margin-right: calc(100% - 80vw);
  color: azure;
  margin-bottom: 150px !important;
}

.container h4 {
  font-size: 1.25rem;
}

.skills {
  display: grid;
  grid-gap: 95px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  font-size: 1.15rem;
}

.skills__cards {
  display: grid;
  grid-gap: 12px;
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.skills__cards__infra {
  margin-top: 12px;
  display: grid;
  grid-gap: 12px;
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.skills__cards__per {
  background-color: #111;
  padding: 12px;
  text-align: center;
  border-radius: 24px;
  box-shadow: rgb(0 0 0 / 80%) 0px 40px 58px -16px,
    rgb(0 0 0 / 72%) 0px 30px 22px -10px;
  border: 2px solid rgb(157, 173, 1);
}

.skills__cards__per:hover {
  transform: scale(1.05);
  transition: all 0.3s ease-in-out;
  box-shadow: -8px -8px 16px #1f2020, 13px 13px 26px #000;
}

@media (max-width: 731px) {
  .skills__cards {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .skills__cards__infra {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (max-width: 768px) {
  .skills__cards {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .skills__cards__infra {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (max-width: 411px) {
  .skills__cards {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .skills__cards__infra {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (max-width: 414px) {
  .skills__cards {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .skills__cards__infra {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.contactheader {
  color: white;
  margin-left: calc(100% - 80vw);
  margin-bottom: 48px;
  margin-top: 150px !important;
  font-size: 2.05rem;
  font-weight: 700;
}

.contact {
  display: flex;
  color: white;
  margin-left: calc(100% - 80vw);
  margin-right: calc(100% - 62vw);
}

.cdetails {
  margin-right: calc(100% - 45vw);
}

.contactform .fields {
  display: flex;
  justify-content: center;
}

.contactform .btnfield {
  display: flex;
  justify-content: center;
}

.contact_details .phone h4,
.contact_details .phone a {
  display: inline;
  vertical-align: center;
  line-height: 28px;
}

.contact_details .phone a,
.contact_details .email a {
  border-bottom: 2px solid rgb(157, 173, 1) !important;
}

.contact_details .phone {
  margin-bottom: 16px !important;
}

.contact_details .email h4,
.contact_details .email a {
  display: inline;
  vertical-align: center;
  line-height: 28px;
}

.contact_details .email {
  margin-bottom: 48px !important;
}

.socials {
  display: flex;
}

.socials .linkedin {
  margin-bottom: 56px !important;
  margin-right: 24px;
}

.socials .linkedin a {
  border: 2px solid rgb(157, 173, 1) !important;
  background-color: #111;
  padding: 12px;
  text-align: center;
  border-radius: 16px;
  box-shadow: rgb(0 0 0 / 80%) 0px 40px 58px -16px,
    rgb(0 0 0 / 72%) 0px 30px 22px -10px;
}

.socials .linkedin a:hover {
  transform: scale(1.05);
  transition: all 0.5s ease-in-out;
  box-shadow: -8px -8px 16px #1f2020, 13px 13px 26px #000;
}

.socials .github {
  margin-bottom: 24px !important;
}

.socials .github a {
  border: 2px solid rgb(157, 173, 1) !important;
  background-color: #111;
  padding: 12px;
  text-align: center;
  border-radius: 16px;
  box-shadow: rgb(0 0 0 / 80%) 0px 40px 58px -16px,
    rgb(0 0 0 / 72%) 0px 30px 22px -10px;
}

.socials .github a:hover {
  transition: all 0.5s ease-in-out;
  box-shadow: -8px -8px 16px #1f2020, 13px 13px 26px #000;
}

.fields input {
  background-color: #111;
  border: 2px solid rgb(157, 173, 1) !important;
  border-radius: 16px;
  padding: 12px 12px 12px 12px;
  box-shadow: rgb(0 0 0 / 80%) 0px 40px 58px -16px,
    rgb(0 0 0 / 72%) 0px 30px 22px -10px;
  min-width: 450px !important;
  margin-bottom: 24px;
  line-height: 25px;
  color: white;
}

.fields input:hover {
  transform: scale(1.05);
  transition: all 0.3s ease-in-out;
  box-shadow: -8px -8px 16px #1f2020, 13px 13px 26px #000;
}

.fields textarea {
  background-color: #111;
  border: 2px solid rgb(157, 173, 1) !important;
  border-radius: 16px;
  padding: 12px 12px 12px 12px;
  box-shadow: rgb(0 0 0 / 80%) 0px 40px 58px -16px,
    rgb(0 0 0 / 72%) 0px 30px 22px -10px;
  min-width: 450px !important;
  margin-bottom: 28px;
  color: white;
}

.fields textarea:hover {
  transform: scale(1.05);
  transition: all 0.3s ease-in-out;
  box-shadow: -8px -8px 16px #1f2020, 13px 13px 26px #000;
}

.contactbtn {
  margin-top: 12px;
  background: #111;
  height: 45px;
  text-align: center;
  line-height: 45px;
  text-decoration: none;
  color: #fff;
  border-radius: 16px;
  min-width: 150px;
  border: 2px solid rgb(157, 173, 1) !important;
  box-shadow: 0px 0px 15px 7px #343a40;
}

.contactbtn:hover {
  transform: scale(1.05);
  transition: all 0.3s ease-in-out;
  box-shadow: -8px -8px 16px #1f2020, 13px 13px 26px #000;
}

@media (max-width: 731px) {
  .contact {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .fields input {
    border-radius: 16px;
    padding: 12px 12px 12px 12px;
    box-shadow: rgb(0 0 0 / 80%) 0px 40px 58px -16px,
      rgb(0 0 0 / 72%) 0px 30px 22px -10px;
    min-width: 250px !important;
    margin-bottom: 24px;
    line-height: 25px;
  }

  .fields textarea {
    border-radius: 16px;
    padding: 12px 12px 12px 12px;
    box-shadow: rgb(0 0 0 / 80%) 0px 40px 58px -16px,
      rgb(0 0 0 / 72%) 0px 30px 22px -10px;
    min-width: 250px !important;
    margin-bottom: 28px;
  }
}

@media (max-width: 768px) {
  .contact {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .contact.row {
    margin-bottom: 50px;
  }

  .cform {
    display: flex;
  }

  .fields input {
    border-radius: 16px;
    padding: 12px 12px 12px 12px;
    box-shadow: rgb(0 0 0 / 80%) 0px 40px 58px -16px,
      rgb(0 0 0 / 72%) 0px 30px 22px -10px;
    min-width: 250px !important;
    margin-bottom: 24px;
    line-height: 25px;
  }

  .fields textarea {
    border-radius: 16px;
    padding: 12px 12px 12px 12px;
    box-shadow: rgb(0 0 0 / 80%) 0px 40px 58px -16px,
      rgb(0 0 0 / 72%) 0px 30px 22px -10px;
    min-width: 250px !important;
    margin-bottom: 28px;
  }

  .key {
    overflow-x: scroll;
    overflow-y: scroll;
  }
}

#baslik {
  font-weight: 700;
  font-size: 25px;
  margin-top: 30px;
  cursor: default;
}

.ikon {
  margin: auto;
  width: 70px;
  margin-left: 19px;
  border-radius: 50%;
  margin-top: 80px;
  transition-property: padding, width, background-color;
  transition-duration: 0.4s;
}

.ikon:hover {
  background-color: rgb(157, 173, 1);
  width: 60px;
  padding: 5px;
}

.icerik {
  color: white;
}

.anaDiv {
  width: 600px;
  height: 320px;
  margin: auto;
  background-color: #333;
  text-align: center;
  overflow: hidden;
  border-radius: 50px;
  transition-property: transform;
  transition-duration: 0.8s;
  box-shadow: 0px 0px 26px 0px rgba(0, 0, 0, 0.75);
}

.kapak {
  margin: 0px;
  width: 100%;
  height: 330px;
  background-color: white;
  position: relative;
  bottom: 240px;
  transition-property: transform;
  transition-duration: 0.8s;
  overflow: hidden;
  background-image: url("https://i.pinimg.com/originals/59/f0/54/59f054a8ec06cd7620c73a774ed07eb9.gif");
  background-repeat: no-repeat;
  background-size: 100%;
}
.anaDiv:hover .kapak {
  transform: translate(-600px);
}

@media (max-width: 768px) {
  .anaDiv {
    display: none;
  }
}

.encrypted__box {
  margin-top: 5vw;
  max-width: 75%;
  color: white;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.2),
    inset -5px -5px 15px rgba(255, 255, 255, 0.1),
    5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.1) !important;
  padding: 24px;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
}

.encrypted__box > h4 {
  display: flex;
  justify-content: center;
}

.encrypted__box > hr {
  border: 1px solid rgb(196, 216, 1);
  max-width: 25vw;
}

.encrypted__box > p {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.anim {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.anim section {
  transition: 200ms ease;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.anim section div {
  position: absolute;
}
.anim section div:nth-child(4) {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #c4d801;
  animation: animateFade 2s infinite linear;
}
.anim section div:nth-child(1) {
  width: 25vh;
  height: 25vh;
  border-top: 10px solid #c4d801;
  border-left: 12px solid #c4d801;
  border-radius: 50%;
  animation: animateSpin-1 2s infinite linear;
}
.anim section div:nth-child(2) {
  width: 25vh;
  height: 25vh;
  border-top: 10px solid #c4d801;
  border-left: 12px solid #c4d801;
  border-radius: 50%;
  animation: animateSpin-2 2s infinite linear;
}
.anim section div:nth-child(3) {
  width: 25vh;
  height: 25vh;
  border-top: 10px solid #c4d801;
  border-left: 12px solid #c4d801;
  border-radius: 50%;
  animation: animateSpin-3 2s infinite linear;
}

@keyframes animateSpin-1 {
  0% {
    transform: rotatez(120deg) rotatex(50deg) rotatez(0deg);
  }
  100% {
    transform: rotatez(120deg) rotatex(50deg) rotatez(360deg);
  }
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    box-shadow: -2px -2px 20px #c4d801;
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    box-shadow: -2px -2px 5px #c4d801;
  }
}
@keyframes animateSpin-2 {
  0% {
    transform: rotatez(240deg) rotatex(50deg) rotatez(0deg);
  }
  100% {
    transform: rotatez(240deg) rotatex(50deg) rotatez(360deg);
  }
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    box-shadow: -2px -2px 20px #c4d801;
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    box-shadow: -2px -2px 5px #c4d801;
  }
}
@keyframes animateSpin-3 {
  0% {
    transform: rotatez(360deg) rotatex(50deg) rotatez(0deg);
  }
  100% {
    transform: rotatez(360deg) rotatex(50deg) rotatez(360deg);
  }
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    box-shadow: -2px -2px 20px #c4d801;
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    box-shadow: -2px -2px 5px #c4d801;
  }
}
@keyframes animateSpin-4 {
  0% {
    transform: rotatez(480deg) rotatex(50deg) rotatez(0deg);
  }
  100% {
    transform: rotatez(480deg) rotatex(50deg) rotatez(360deg);
  }
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    box-shadow: -2px -2px 20px #c4d801;
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    box-shadow: -2px -2px 5px #c4d801;
  }
}
@keyframes animateSpin-5 {
  0% {
    transform: rotatez(600deg) rotatex(50deg) rotatez(0deg);
  }
  100% {
    transform: rotatez(600deg) rotatex(50deg) rotatez(360deg);
  }
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    box-shadow: -2px -2px 20px #c4d801;
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    box-shadow: -2px -2px 5px #c4d801;
  }
}
@keyframes animateSpin-6 {
  0% {
    transform: rotatez(720deg) rotatex(50deg) rotatez(0deg);
  }
  100% {
    transform: rotatez(720deg) rotatex(50deg) rotatez(360deg);
  }
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    box-shadow: -2px -2px 20px #c4d801;
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    box-shadow: -2px -2px 5px #c4d801;
  }
}
@keyframes animateSpin-7 {
  0% {
    transform: rotatez(840deg) rotatex(50deg) rotatez(0deg);
  }
  100% {
    transform: rotatez(840deg) rotatex(50deg) rotatez(360deg);
  }
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    box-shadow: -2px -2px 20px #c4d801;
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    box-shadow: -2px -2px 5px #c4d801;
  }
}
@keyframes animateSpin-8 {
  0% {
    transform: rotatez(960deg) rotatex(50deg) rotatez(0deg);
  }
  100% {
    transform: rotatez(960deg) rotatex(50deg) rotatez(360deg);
  }
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    box-shadow: -2px -2px 20px #c4d801;
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    box-shadow: -2px -2px 5px #c4d801;
  }
}
@keyframes animateSpin-9 {
  0% {
    transform: rotatez(1080deg) rotatex(50deg) rotatez(0deg);
  }
  100% {
    transform: rotatez(1080deg) rotatex(50deg) rotatez(360deg);
  }
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    box-shadow: -2px -2px 20px #c4d801;
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    box-shadow: -2px -2px 5px #c4d801;
  }
}
@keyframes animateSpin-10 {
  0% {
    transform: rotatez(1200deg) rotatex(50deg) rotatez(0deg);
  }
  100% {
    transform: rotatez(1200deg) rotatex(50deg) rotatez(360deg);
  }
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    box-shadow: -2px -2px 20px #c4d801;
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    box-shadow: -2px -2px 5px #c4d801;
  }
}
@keyframes animateSpin-11 {
  0% {
    transform: rotatez(1320deg) rotatex(50deg) rotatez(0deg);
  }
  100% {
    transform: rotatez(1320deg) rotatex(50deg) rotatez(360deg);
  }
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    box-shadow: -2px -2px 20px #c4d801;
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    box-shadow: -2px -2px 5px #c4d801;
  }
}
@keyframes animateFade {
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    transform: scale(0.8);
    box-shadow: -2px 0px 20px #c4d801;
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: scale(1);
    box-shadow: -2px 0px 5px #c4d801;
  }
}

.green-line {
  border: 1px solid rgb(196, 216, 1);
  display: flex;
}

.card {
  display: flex;
  border-radius: 20%;
  min-height: 45vh;
  max-height: 45vh;
  max-width: 22vw;
  margin-top: 15vh;
  margin-left: 5vw;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.2),
    inset -5px -5px 15px rgba(255, 255, 255, 0.1),
    5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.1) !important;
  padding: 12px;
  border: 1px solid rgb(196, 216, 1);
}

.card img {
  max-width: 20vw;
  min-width: 20vw;
}

@media (max-width: 768px) {
  .card {
    display: flex;
    border-radius: 20%;
    min-height: 45vh;
    min-width: 35vh;
    max-width: 35vh;
  }
}

.docs {
  margin: 0;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.results-type {
  display: none !important;
}

#the-body {
  border: none !important;
}

iframe #the-body {
  display: none;
}

.nav {
  position: fixed;
  top: 0;
  width: 100%;
  height: 50px;
  z-index: 1;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  transition-timing-function: ease-in;
  transition: all 0.5s;
}

.nav__black {
  background-color: #111;
}

.nav__black img:first-child {
  box-shadow: rgb(0 0 0 / 100%) 0px 40px 58px -16px,
    rgb(0 0 0 / 72%) 0px 30px 22px -10px !important;
}

.nav__logo {
  position: fixed;
  left: 20px;
  width: 100px;
  border-radius: 25px;
  /* margin-top: -px; */
  object-fit: contain;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.2),
    inset -5px -5px 15px rgba(255, 255, 255, 0.1),
    5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.1) !important;
}

.nav__avatar {
  position: fixed;
  right: 20px;
  width: 30px;
  object-fit: contain;
}

