.contactheader {
  color: white;
  margin-left: calc(100% - 80vw);
  margin-bottom: 48px;
  margin-top: 150px !important;
  font-size: 2.05rem;
  font-weight: 700;
}

.contact {
  display: flex;
  color: white;
  margin-left: calc(100% - 80vw);
  margin-right: calc(100% - 62vw);
}

.cdetails {
  margin-right: calc(100% - 45vw);
}

.contactform .fields {
  display: flex;
  justify-content: center;
}

.contactform .btnfield {
  display: flex;
  justify-content: center;
}

.contact_details .phone h4,
.contact_details .phone a {
  display: inline;
  vertical-align: center;
  line-height: 28px;
}

.contact_details .phone a,
.contact_details .email a {
  border-bottom: 2px solid rgb(157, 173, 1) !important;
}

.contact_details .phone {
  margin-bottom: 16px !important;
}

.contact_details .email h4,
.contact_details .email a {
  display: inline;
  vertical-align: center;
  line-height: 28px;
}

.contact_details .email {
  margin-bottom: 48px !important;
}

.socials {
  display: flex;
}

.socials .linkedin {
  margin-bottom: 56px !important;
  margin-right: 24px;
}

.socials .linkedin a {
  border: 2px solid rgb(157, 173, 1) !important;
  background-color: #111;
  padding: 12px;
  text-align: center;
  border-radius: 16px;
  box-shadow: rgb(0 0 0 / 80%) 0px 40px 58px -16px,
    rgb(0 0 0 / 72%) 0px 30px 22px -10px;
}

.socials .linkedin a:hover {
  transform: scale(1.05);
  transition: all 0.5s ease-in-out;
  box-shadow: -8px -8px 16px #1f2020, 13px 13px 26px #000;
}

.socials .github {
  margin-bottom: 24px !important;
}

.socials .github a {
  border: 2px solid rgb(157, 173, 1) !important;
  background-color: #111;
  padding: 12px;
  text-align: center;
  border-radius: 16px;
  box-shadow: rgb(0 0 0 / 80%) 0px 40px 58px -16px,
    rgb(0 0 0 / 72%) 0px 30px 22px -10px;
}

.socials .github a:hover {
  transition: all 0.5s ease-in-out;
  box-shadow: -8px -8px 16px #1f2020, 13px 13px 26px #000;
}

.fields input {
  background-color: #111;
  border: 2px solid rgb(157, 173, 1) !important;
  border-radius: 16px;
  padding: 12px 12px 12px 12px;
  box-shadow: rgb(0 0 0 / 80%) 0px 40px 58px -16px,
    rgb(0 0 0 / 72%) 0px 30px 22px -10px;
  min-width: 450px !important;
  margin-bottom: 24px;
  line-height: 25px;
  color: white;
}

.fields input:hover {
  transform: scale(1.05);
  transition: all 0.3s ease-in-out;
  box-shadow: -8px -8px 16px #1f2020, 13px 13px 26px #000;
}

.fields textarea {
  background-color: #111;
  border: 2px solid rgb(157, 173, 1) !important;
  border-radius: 16px;
  padding: 12px 12px 12px 12px;
  box-shadow: rgb(0 0 0 / 80%) 0px 40px 58px -16px,
    rgb(0 0 0 / 72%) 0px 30px 22px -10px;
  min-width: 450px !important;
  margin-bottom: 28px;
  color: white;
}

.fields textarea:hover {
  transform: scale(1.05);
  transition: all 0.3s ease-in-out;
  box-shadow: -8px -8px 16px #1f2020, 13px 13px 26px #000;
}

.contactbtn {
  margin-top: 12px;
  background: #111;
  height: 45px;
  text-align: center;
  line-height: 45px;
  text-decoration: none;
  color: #fff;
  border-radius: 16px;
  min-width: 150px;
  border: 2px solid rgb(157, 173, 1) !important;
  box-shadow: 0px 0px 15px 7px #343a40;
}

.contactbtn:hover {
  transform: scale(1.05);
  transition: all 0.3s ease-in-out;
  box-shadow: -8px -8px 16px #1f2020, 13px 13px 26px #000;
}

@media (max-width: 731px) {
  .contact {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .fields input {
    border-radius: 16px;
    padding: 12px 12px 12px 12px;
    box-shadow: rgb(0 0 0 / 80%) 0px 40px 58px -16px,
      rgb(0 0 0 / 72%) 0px 30px 22px -10px;
    min-width: 250px !important;
    margin-bottom: 24px;
    line-height: 25px;
  }

  .fields textarea {
    border-radius: 16px;
    padding: 12px 12px 12px 12px;
    box-shadow: rgb(0 0 0 / 80%) 0px 40px 58px -16px,
      rgb(0 0 0 / 72%) 0px 30px 22px -10px;
    min-width: 250px !important;
    margin-bottom: 28px;
  }
}

@media (max-width: 768px) {
  .contact {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .contact.row {
    margin-bottom: 50px;
  }

  .cform {
    display: flex;
  }

  .fields input {
    border-radius: 16px;
    padding: 12px 12px 12px 12px;
    box-shadow: rgb(0 0 0 / 80%) 0px 40px 58px -16px,
      rgb(0 0 0 / 72%) 0px 30px 22px -10px;
    min-width: 250px !important;
    margin-bottom: 24px;
    line-height: 25px;
  }

  .fields textarea {
    border-radius: 16px;
    padding: 12px 12px 12px 12px;
    box-shadow: rgb(0 0 0 / 80%) 0px 40px 58px -16px,
      rgb(0 0 0 / 72%) 0px 30px 22px -10px;
    min-width: 250px !important;
    margin-bottom: 28px;
  }

  .key {
    overflow-x: scroll;
    overflow-y: scroll;
  }
}

#baslik {
  font-weight: 700;
  font-size: 25px;
  margin-top: 30px;
  cursor: default;
}

.ikon {
  margin: auto;
  width: 70px;
  margin-left: 19px;
  border-radius: 50%;
  margin-top: 80px;
  transition-property: padding, width, background-color;
  transition-duration: 0.4s;
}

.ikon:hover {
  background-color: rgb(157, 173, 1);
  width: 60px;
  padding: 5px;
}

.icerik {
  color: white;
}

.anaDiv {
  width: 600px;
  height: 320px;
  margin: auto;
  background-color: #333;
  text-align: center;
  overflow: hidden;
  border-radius: 50px;
  transition-property: transform;
  transition-duration: 0.8s;
  -webkit-box-shadow: 0px 0px 26px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 26px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 26px 0px rgba(0, 0, 0, 0.75);
}

.kapak {
  margin: 0px;
  width: 100%;
  height: 330px;
  background-color: white;
  position: relative;
  bottom: 240px;
  transition-property: transform;
  transition-duration: 0.8s;
  overflow: hidden;
  background-image: url("https://i.pinimg.com/originals/59/f0/54/59f054a8ec06cd7620c73a774ed07eb9.gif");
  background-repeat: no-repeat;
  background-size: 100%;
}
.anaDiv:hover .kapak {
  transform: translate(-600px);
}

@media (max-width: 768px) {
  .anaDiv {
    display: none;
  }
}
