.green-line {
  border: 1px solid rgb(196, 216, 1);
  display: flex;
}

.card {
  display: flex;
  border-radius: 20%;
  min-height: 45vh;
  max-height: 45vh;
  max-width: 22vw;
  margin-top: 15vh;
  margin-left: 5vw;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.2),
    inset -5px -5px 15px rgba(255, 255, 255, 0.1),
    5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.1) !important;
  padding: 12px;
  border: 1px solid rgb(196, 216, 1);
}

.card img {
  max-width: 20vw;
  min-width: 20vw;
}

@media (max-width: 768px) {
  .card {
    display: flex;
    border-radius: 20%;
    min-height: 45vh;
    min-width: 35vh;
    max-width: 35vh;
  }
}

.docs {
  margin: 0;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.results-type {
  display: none !important;
}

#the-body {
  border: none !important;
}

iframe #the-body {
  display: none;
}
