@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");

.nav {
  position: fixed;
  top: 0;
  width: 100%;
  height: 50px;
  z-index: 1;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  transition-timing-function: ease-in;
  transition: all 0.5s;
}

.nav__black {
  background-color: #111;
}

.nav__black img:first-child {
  box-shadow: rgb(0 0 0 / 100%) 0px 40px 58px -16px,
    rgb(0 0 0 / 72%) 0px 30px 22px -10px !important;
}

.nav__logo {
  position: fixed;
  left: 20px;
  width: 100px;
  border-radius: 25px;
  /* margin-top: -px; */
  object-fit: contain;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.2),
    inset -5px -5px 15px rgba(255, 255, 255, 0.1),
    5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.1) !important;
}

.nav__avatar {
  position: fixed;
  right: 20px;
  width: 30px;
  object-fit: contain;
}
