.encrypted__box {
  margin-top: 5vw;
  max-width: 75%;
  color: white;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.2),
    inset -5px -5px 15px rgba(255, 255, 255, 0.1),
    5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.1) !important;
  padding: 24px;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
}

.encrypted__box > h4 {
  display: flex;
  justify-content: center;
}

.encrypted__box > hr {
  border: 1px solid rgb(196, 216, 1);
  max-width: 25vw;
}

.encrypted__box > p {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
