.anim {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.anim section {
  transition: 200ms ease;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.anim section div {
  position: absolute;
}
.anim section div:nth-child(4) {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #c4d801;
  animation: animateFade 2s infinite linear;
}
.anim section div:nth-child(1) {
  width: 25vh;
  height: 25vh;
  border-top: 10px solid #c4d801;
  border-left: 12px solid #c4d801;
  border-radius: 50%;
  animation: animateSpin-1 2s infinite linear;
}
.anim section div:nth-child(2) {
  width: 25vh;
  height: 25vh;
  border-top: 10px solid #c4d801;
  border-left: 12px solid #c4d801;
  border-radius: 50%;
  animation: animateSpin-2 2s infinite linear;
}
.anim section div:nth-child(3) {
  width: 25vh;
  height: 25vh;
  border-top: 10px solid #c4d801;
  border-left: 12px solid #c4d801;
  border-radius: 50%;
  animation: animateSpin-3 2s infinite linear;
}

@keyframes animateSpin-1 {
  0% {
    transform: rotatez(120deg) rotatex(50deg) rotatez(0deg);
  }
  100% {
    transform: rotatez(120deg) rotatex(50deg) rotatez(360deg);
  }
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    box-shadow: -2px -2px 20px #c4d801;
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    box-shadow: -2px -2px 5px #c4d801;
  }
}
@keyframes animateSpin-2 {
  0% {
    transform: rotatez(240deg) rotatex(50deg) rotatez(0deg);
  }
  100% {
    transform: rotatez(240deg) rotatex(50deg) rotatez(360deg);
  }
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    box-shadow: -2px -2px 20px #c4d801;
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    box-shadow: -2px -2px 5px #c4d801;
  }
}
@keyframes animateSpin-3 {
  0% {
    transform: rotatez(360deg) rotatex(50deg) rotatez(0deg);
  }
  100% {
    transform: rotatez(360deg) rotatex(50deg) rotatez(360deg);
  }
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    box-shadow: -2px -2px 20px #c4d801;
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    box-shadow: -2px -2px 5px #c4d801;
  }
}
@keyframes animateSpin-4 {
  0% {
    transform: rotatez(480deg) rotatex(50deg) rotatez(0deg);
  }
  100% {
    transform: rotatez(480deg) rotatex(50deg) rotatez(360deg);
  }
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    box-shadow: -2px -2px 20px #c4d801;
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    box-shadow: -2px -2px 5px #c4d801;
  }
}
@keyframes animateSpin-5 {
  0% {
    transform: rotatez(600deg) rotatex(50deg) rotatez(0deg);
  }
  100% {
    transform: rotatez(600deg) rotatex(50deg) rotatez(360deg);
  }
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    box-shadow: -2px -2px 20px #c4d801;
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    box-shadow: -2px -2px 5px #c4d801;
  }
}
@keyframes animateSpin-6 {
  0% {
    transform: rotatez(720deg) rotatex(50deg) rotatez(0deg);
  }
  100% {
    transform: rotatez(720deg) rotatex(50deg) rotatez(360deg);
  }
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    box-shadow: -2px -2px 20px #c4d801;
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    box-shadow: -2px -2px 5px #c4d801;
  }
}
@keyframes animateSpin-7 {
  0% {
    transform: rotatez(840deg) rotatex(50deg) rotatez(0deg);
  }
  100% {
    transform: rotatez(840deg) rotatex(50deg) rotatez(360deg);
  }
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    box-shadow: -2px -2px 20px #c4d801;
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    box-shadow: -2px -2px 5px #c4d801;
  }
}
@keyframes animateSpin-8 {
  0% {
    transform: rotatez(960deg) rotatex(50deg) rotatez(0deg);
  }
  100% {
    transform: rotatez(960deg) rotatex(50deg) rotatez(360deg);
  }
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    box-shadow: -2px -2px 20px #c4d801;
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    box-shadow: -2px -2px 5px #c4d801;
  }
}
@keyframes animateSpin-9 {
  0% {
    transform: rotatez(1080deg) rotatex(50deg) rotatez(0deg);
  }
  100% {
    transform: rotatez(1080deg) rotatex(50deg) rotatez(360deg);
  }
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    box-shadow: -2px -2px 20px #c4d801;
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    box-shadow: -2px -2px 5px #c4d801;
  }
}
@keyframes animateSpin-10 {
  0% {
    transform: rotatez(1200deg) rotatex(50deg) rotatez(0deg);
  }
  100% {
    transform: rotatez(1200deg) rotatex(50deg) rotatez(360deg);
  }
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    box-shadow: -2px -2px 20px #c4d801;
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    box-shadow: -2px -2px 5px #c4d801;
  }
}
@keyframes animateSpin-11 {
  0% {
    transform: rotatez(1320deg) rotatex(50deg) rotatez(0deg);
  }
  100% {
    transform: rotatez(1320deg) rotatex(50deg) rotatez(360deg);
  }
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    box-shadow: -2px -2px 20px #c4d801;
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    box-shadow: -2px -2px 5px #c4d801;
  }
}
@keyframes animateFade {
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    transform: scale(0.8);
    box-shadow: -2px 0px 20px #c4d801;
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: scale(1);
    box-shadow: -2px 0px 5px #c4d801;
  }
}
